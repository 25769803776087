import styled from "styled-components";

const Styles = {
    PageContainer: styled.div`
        width: ${(props) => props.theme.breakPoints.desktop};
        margin-left: 15px;
        margin-right: 15px;
    `,
    PageWrapper: styled.div`
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    `
}
export default Styles