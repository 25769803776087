import React, { PropsWithChildren } from 'react'
import Styles from './styles'
import Footer from '../../domain/Footer'

interface ILayout {}

const { PageWrapper, PageContainer } = Styles

const Layout = ({ children }: PropsWithChildren<ILayout>) => {
    return (
        <>
            <PageWrapper>
                <PageContainer>
                    {children}
                </PageContainer>
            </PageWrapper>
            <Footer />
        </>
    )
}

export default Layout
